import React from 'react';
import Image from '@ui/components/core/image';
import { type Image as StaticImage } from '@ui/providers/static-content-provider';
import Link from 'next/link';

export type HomeHeroSectionProps = {
  url: string;
  desktopImage: StaticImage;
  mobileImage: StaticImage;
};

const HomeHeroSection: React.FC<HomeHeroSectionProps> = ({ url, desktopImage, mobileImage }) => {
  return (
    <Link href={url} className="relative block w-full">
      <div className="hidden md:flex transition-all">
        <Image
          fetchPriority="high"
          loading="eager"
          alt={desktopImage.alt}
          height={desktopImage.height}
          width={desktopImage.width}
          src={desktopImage.src}
          className="w-full h-auto object-cover"
        />
      </div>
      <div className="flex md:hidden transition-all">
        <Image
          fetchPriority="high"
          loading="eager"
          alt={mobileImage.alt}
          height={mobileImage.height}
          width={mobileImage.width}
          src={mobileImage.src}
          className="w-full h-auto object-cover"
          srcSet={`${mobileImage.src} 320w, ${mobileImage.src} 480w, ${mobileImage.src} 800w`}
        />
      </div>
    </Link>
  );
};

export default HomeHeroSection;
