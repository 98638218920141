import Image from '@ui/components/core/image';

type UpArrowIconProps = {
  height: number;
  width: number;
  className?: string;
};

const UpArrowIcon = ({ height, width, className }: UpArrowIconProps) => (
  <Image
    src={'/common/upArrowIcon.svg'}
    alt="Hello Molly Up Arrow Icon"
    height={height}
    width={width}
    className={className}
  />
);

UpArrowIcon.defaultProps = {
  className: undefined,
};

export default UpArrowIcon;
