import { Container } from '@ui/components/core';
import HomeCategorySection from '../HomeCategorySection/HomeCategorySection';
import HomeHeroSection from '../HomeHeroSection/HomeHeroSection';
import HomeHeroWithVideoSection from '../HomeHeroSection/HomeHeroWithVideoSection';
import env from '@ui/env';
import HomeMiddleBannerSection from '../HomeMiddleBannerSection/HomeMiddleBannerSection';
import HomeRecommendationsSection from '../HomeRecommendationsSection/HomeRecommendationsSection';
import HomeRecentlyViewedSection from '../HomeRecentlyViewedSection/HomeRecentlyViewedSection';
import TolstoyCarouselSection from '@ui/components/shared/sections/TolstoyCarouselSection';
import { useInView } from 'react-intersection-observer';
import { ScrollToTopButton } from '@ui/components/shared';
import { useStaticContent } from '@ui/providers/static-content-provider';
import useScarabTracking from '@ui/hooks/useScarabTracking';
import HomeHeroVideoBanner from '../HomeHeroSection/HomeHeroVideoBanner';

export default function HomePage() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const defaultHeroBannerUrl = useStaticContent('Home.HeroBannerUrl');
  const defaultHeroBannerDesktopImage = useStaticContent('Home.HeroBannerImageDesktop');
  const defaultHeroBannerMobileImage = useStaticContent('Home.HeroBannerImageMobile');
  const heroVideoMobile = useStaticContent('Home.HeroBannerVideoMobile');
  const heroVideoDesktop = useStaticContent('Home.HeroBannerVideoDesktop');
  useScarabTracking({});

  return (
    <>
      <div className="pb-16 sm:pb-20">
        {!!(heroVideoMobile && heroVideoDesktop && !env.FEATURE_VIDEO_BANNER)  ? (
          <HomeHeroWithVideoSection
            url={defaultHeroBannerUrl}
            desktopImage={defaultHeroBannerDesktopImage}
            mobileImage={defaultHeroBannerMobileImage}
            desktopVideo={heroVideoDesktop}
            mobileVideo={heroVideoMobile}
          />
        ) : !!(heroVideoMobile && heroVideoDesktop && env.FEATURE_VIDEO_BANNER) ? (
          <HomeHeroVideoBanner
            url={defaultHeroBannerUrl}
            desktopImage={defaultHeroBannerDesktopImage}
            mobileImage={defaultHeroBannerMobileImage}
            desktopVideo={heroVideoDesktop}
            mobileVideo={heroVideoMobile}
          />
        ) : (
          <HomeHeroSection
            url={defaultHeroBannerUrl}
            desktopImage={defaultHeroBannerDesktopImage}
            mobileImage={defaultHeroBannerMobileImage}
          />
        )}
        <Container className="mt-8 md:mt-16">
          <HomeCategorySection />
        </Container>
        {env.FEATURE_HOME_MIDDLE_BANNER_SECTION && (
          <Container className="mt-4 md:mt-16" px={0} maw="100%">
            <HomeMiddleBannerSection />
          </Container>
        )}
        <Container className="[&>div]:py-8 md:[&>div]:py-16 divide-y-[0.6px] divide-black" ref={ref}>
          {inView && (
            <>
              <HomeRecommendationsSection />
              <HomeRecentlyViewedSection 
                carouselRootClassName="px-2"
                swiperOptions={{
                  spaceBetween: 16,
                  slidesPerView: 1.2,
                  breakpoints: {
                    640: { slidesPerView: 2.2 },
                    768: { slidesPerView: 3.2 },
                    1024: { slidesPerView: 4.2 },
                    1200: { slidesPerView: 5.2 },
                }}}
              />
              {env.FEATURE_TOLSTOY && <TolstoyCarouselSection tolstoyCarouselId={env.NEXT_PUBLIC_TOLSTOY_HOMEPAGE_ID || ""} title={"AS SEEN ON @HELLOMOLLY"} hashTag={true}/> }
            </>
          )}
        </Container>
      </div>
      <ScrollToTopButton />
    </>
  );
}
