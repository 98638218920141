import React from 'react';
import { NextLink } from '../../../core';
import { useStaticContent } from '@ui/providers/static-content-provider';
import Image from '@ui/components/core/image';

const HomeMiddleBannerSection = (): React.ReactElement => {
  const desktop = useStaticContent('Home.MiddleBannerDesktop');
  const mobile = useStaticContent('Home.MiddleBannerMobile');

  return (
    <div>
      <NextLink href={desktop.href} className="hidden md:block">
        <Image
          src={desktop.src}
          alt={desktop.alt}
          width={desktop.width}
          height={desktop.height}
          className="w-full h-auto object-cover"
        />
      </NextLink>
      <NextLink href={mobile.href} className="block md:hidden">
        <Image
          src={mobile.src}
          alt={mobile.alt}
          width={mobile.width}
          height={mobile.height}
          className="w-full h-auto object-cover"
          srcSet={`${mobile.src} 320w, ${mobile.src} 480w, ${mobile.src} 800w`}
        />
      </NextLink>
    </div>
  );
};

export default HomeMiddleBannerSection;
