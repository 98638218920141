import React, { useEffect } from 'react';
import { UpArrowIcon } from '@ui/components/core';
import cn from '@ui/utils/cn';
import { Button } from '@ui/components/core/button';

type ScrollToTopButtonProps = {
  className?: string;
};

const ScrollToTopButton:React.FC<ScrollToTopButtonProps> = ({ className }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const onScroll = () => {
      const ceiling = window.innerHeight / 2;

      if (document.body.scrollTop > ceiling || document.documentElement.scrollTop > ceiling) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);


  return (
    <Button
      className={cn("fixed bottom-6 right-6 z-[1000] rounded-full w-12 h-12 px-0",open ? "opacity-1" : "opacity-0 disabled pointer-events-none", className)}
      disabled={!open}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <div className='w-[70%] h-[70%]'>
        <UpArrowIcon height={15} width={15} className='w-full h-full' />
      </div>
  </Button>
  )
};

export default ScrollToTopButton;
